/**
 * 取出对象里的同名属性值
 */
export function extractProperties(source, target) {
  //案例 this.doctorInfo = extractProperties(this.doctorInfo, options);
  let result = {}
  Object.keys(source).forEach((key) => {
    if (target.hasOwnProperty(key)) {
      result[key] = target[key]
    }
  })
  result = { ...source, ...result }//保留source里面的属性值
  return result;
}

/**
 * 计算上一次消息时间
 */
export function caculateTimeago(dateTimestamp) {
  if (!dateTimestamp) return
  const second = 1; // 秒
  const minute = 60 * second; // 分钟
  const hour = 60 * minute; // 小时
  const day = 24 * hour; // 天
  const week = 7 * day; // 周
  const monthApprox = 30 * day; // 大约一个月（以30天计算）
  const now = Math.floor(Date.now() / 1000); // 当前时间（秒）
  const diffValue = now - dateTimestamp; // 时间差（秒）

  if (diffValue < 0) {
    return '';
  }

  if (diffValue < minute) {
    return '刚刚';
  } else if (diffValue < 2 * minute) {
    return '1分钟前';
  } else if (diffValue < hour) {
    return `${Math.floor(diffValue / minute)}分钟前`;
  } else if (diffValue < 2 * hour) {
    return '1小时前';
  } else if (diffValue < day) {
    return `${Math.floor(diffValue / hour)}小时前`;
  } else if (diffValue < 2 * day) {
    return '1天前';
  } else if (diffValue < week) {
    return `${Math.floor(diffValue / day)}天前`;
  } else if (diffValue < 2 * week) {
    return '1周前';
  } else if (diffValue < 4 * week) {
    return `${Math.floor(diffValue / week)}周前`;
  } else if (diffValue < 2 * monthApprox) {
    return '1个月前';
  } else if (diffValue < 12 * monthApprox) {
    return `${Math.floor(diffValue / monthApprox)}个月前`;
  } else {
    const datetime = new Date(dateTimestamp * 1000);
    const year = datetime.getFullYear();
    const month = (datetime.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以加1
    const date = datetime.getDate().toString().padStart(2, '0');

    // 确保返回的日期晚于1970年1月1日
    if (year >= 1970) {
      return `${year}/${month}/${date}`;
    } else {
      return `${Math.floor(diffValue / (365 * day))}年前`;
    }
  }
}

// 手机号加密 152****8632
export function encryptPhoneNumber(phoneNumber) {
  // 检查输入是否为有效的 11 位手机号
  if (/^\d{11}$/.test(phoneNumber)) {
    // 截取手机号的前三位和后四位
    const start = phoneNumber.slice(0, 3);
    const end = phoneNumber.slice(-4);
    // 生成中间的四个星号
    const middle = "****";
    // 拼接加密后的手机号
    return start + middle + end;
  }
  // 如果输入不是有效的 11 位手机号，返回原输入
  return phoneNumber;
}
